/* eslint-disable import/no-cycle */
import { DataService2 } from '../../../config/dataService/dataServiceSecondary';

export const getWellnessOrganizationApi = (values) => {
  return DataService2.get(`/wellness?page=${values.page}&search=${values.search}`);
};
export const createWellnessProgramApi = (values) => {
  return DataService2.post(`/wellness`, values);
};
export const getSosListApi = (value) => {
  return DataService2.get(`/sos-users?page=${value.page}`);
};
