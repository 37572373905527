import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import tpaReducer from './tpa/reducers';
import insuranceReducer from './insurance/reducers';
import organizationReducer from './organization/reducers';
import employeeReducer from './employee/reducers';
import hrReducer from './hr/reducers';
import PolicyReducer from './policy/reducers';
import { loaderReducer } from './loader/loaderReducer';
import rfqReducer from './rfq/reducers';
import claimReducer from './claims/reducers';
import cdReducer from './cdStatement/reducers';
import endorsementReducer from './endorsement/reducers';
import couponReducer from './coupon/reducers';
import wellnessReducer from './wellnessOrganization/reducer';
// Combine your reducers
const appReducers = combineReducers({
  loader: loaderReducer,
  auth: authReducer,
  ChangeLayoutMode,
  tpa: tpaReducer,
  coupon: couponReducer,
  wellness: wellnessReducer,
  insurance: insuranceReducer,
  organization: organizationReducer,
  employee: employeeReducer,
  hr: hrReducer,
  policy: PolicyReducer,
  claim: claimReducer,
  rfq: rfqReducer,
  cd: cdReducer,
  endorsement: endorsementReducer,
});

// Reset root reducer on logout
// const rootReducer = (state, action) => {
//   if (action.type === 'RESET_ALL_DATA') {
//     return appReducers({});
//   }
//   return appReducers(state, action);
// };
const rootReducer = (state, action) => {
  if (action.type === 'RESET_ALL_DATA') {
    return appReducers(undefined, action);
  }
  return appReducers(state, action);
};

export default rootReducer;
