/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';
import { getQueryString } from '../../../utility/utility';

export const addEmployeeApi = (values) => {
  return DataService.post('/employees', values);
};

export const getAllEmployeeApi = () => {
  return DataService.get('/employees');
};

export const searchEmployeeApi = (values) => {
  return DataService.get(`/employees?${getQueryString(values)}`);
};

export const deleteEmployeeApi = (values) => {
  return DataService.delete(`/employees/${values}`);
};

export const updateEmployeeApi = (values) => {
  const { id } = values;
  delete values.id;
  return DataService.put(`/employees/${id}`, values);
};

export const getNextPageEmployeeApi = (values) => {
  return DataService.get(`/employees?${getQueryString(values)}`);
};

export const getEmployeePermissionsApi = () => {
  return DataService.get(`/employees/permissions`);
};

export const employeeActiveApi = (values) => {
  return DataService.put(`/employees/${values.id}/update-status`, values);
};
export const resetPasswordApi = (values) => {
  if (values?.payload?.id !== undefined) {
    return DataService.put(`employees/${values?.payload?.id}/reset-password`);
  }
};

export const getEmployeePolicyListApi = () => {
  return DataService.get(`/employee/policies`);
};

export const getEmployeePolicyDetailApi = (id) => {
  return DataService.get(`/employee/policies/${id}`);
};

export const getEmployeeProfileApi = () => {
  return DataService.get(`/employee/profile`);
};

export const updateEmployeeProfileApi = (values) => {
  return DataService.post(`/employee/profile`, values);
};

export const registerEmployeeApi = (values) => {
  return DataService.post(`/user`, values);
};

export const updateEmployeeMobileApi = (values) => {
  return DataService.post(`/employee/mobile/verify/otp`, values);
};

export const getExportAllLivesApi = () => {
  return DataService.get(`/employee/all_lives/export/csv`);
};
