/* eslint-disable import/no-cycle */
// import Cookies from 'js-cookie';
import { DataService } from '../../../config/dataService/dataService';

export const loginApi = (values) => {
  return DataService.post('/login', values);
};

export const logoutApi = () => {
  return DataService.post('/logout');
};

export const registerApi = (values) => {
  return DataService.post('/register', values);
};

export const changePasswordApi = (values) => {
  return DataService.put('/user/change-password', values);
};

export const forgotPasswordApi = (values) => {
  return DataService.post('/auth/forgot-password', values);
};

export const employeeRegisterApi = (values) => {
  return DataService.post('/employee/verify-mobile', values);
};

export const verifyOtpApi = (values) => {
  return DataService.post('/employee/verify/otp', values);
};

export const createMpinApi = (values) => {
  return DataService.post('/employee/mpin/save', values);
};

export const employeeLoginApi = (values) => {
  return DataService.post('/employee/login', values);
};

export const forgetMpinApi = (values) => {
  return DataService.post('/employee/forgetmpin', values);
};

export const dashboardKpiApi = (values) => {
  return DataService.get(`/dashboard/count?policyType=${values.policyType}&policyActive=${values.policyActive}`);
};

export const getExportPortfolioApi = () => {
  return DataService.get(`portfolio/export`);
};
