import * as types from './actionTypes';

export const getCouponCode = () => {
  return {
    type: types.GET_COUPON_CODE,
  };
};
export const setCouponCodeList = (data) => {
  return {
    type: types.SET_COUPON_CODE_LIST,
    payload: data,
  };
};
export const getCouponCodeSuccess = () => {
  return {
    type: types.GET_COUPON_CODE_SUCCESS,
  };
};
export const getCouponCodeFailure = () => {
  return {
    type: types.GET_COUPON_CODE_FAILURE,
  };
};
