/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../../coupon/actionTypes';
import { startLoader, stopLoader } from '../../../utility/commonFunction';
import { getCouponCodeSuccess, getCouponCodeFailure, setCouponCodeList } from '../../coupon/actions';
import { getCouponApi } from '../requests/couponRequest';

function* getCouponHandler() {
  yield startLoader('transparent');
  try {
    const response = yield call(getCouponApi);
    if (response?.status) {
      yield put(setCouponCodeList(response?.data));
      yield put(getCouponCodeSuccess());
    } else {
      yield put(getCouponCodeFailure());
    }
  } catch (err) {
    yield put(getCouponCodeFailure(err.message || 'Something went wrong'));
    // console.log('error');
  } finally {
    yield stopLoader('transparent');
  }
}

export default function* couponSaga() {
  yield takeLatest(types.GET_COUPON_CODE, getCouponHandler);
}
