import * as types from './actionTypes';

const initialState = {
  loading: false,
  couponCodes: [],
  errors: {},
};

const couponReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case types.GET_COUPON_CODE:
      return {
        ...state,
        loading: true,
      };
    case types.SET_COUPON_CODE_LIST:
      return {
        ...state,
        couponCodes: action.payload,
      };
    case types.GET_COUPON_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.GET_COUPON_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };

    default:
      return state;
  }
};

export default couponReducer;
