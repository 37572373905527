/* eslint-disable import/no-cycle */
import { all, call } from 'redux-saga/effects';
import userSaga from './handlers/userHandler';
import tpaSaga from './handlers/tpaHandler';
import insuranceSaga from './handlers/insuranceHandler';
import employeeSaga from './handlers/employeeHandler';
import organizationSaga from './handlers/organizationHandler';
import hrSaga from './handlers/hrHandler';
import policySaga from './handlers/policyHandler';
import claimSaga from './handlers/claimHandler';
import rfqSaga from './handlers/rfqHandler';
import cdSaga from './handlers/cdHandler';
import endorsementSaga from './handlers/endorsementHandler';
import couponSaga from './handlers/couponHandler';
import wellnessSaga from './handlers/wellnessHandler';

export default function* rootSaga() {
  yield all([
    call(userSaga),
    call(tpaSaga),
    call(insuranceSaga),
    call(claimSaga),
    call(employeeSaga),
    call(organizationSaga),
    call(hrSaga),
    call(policySaga),
    call(rfqSaga),
    call(cdSaga),
    call(endorsementSaga),
    call(wellnessSaga),
    call(couponSaga),
  ]);
}
