import * as types from './actionTypes';

export const getAllPolicy = (data) => {
  return {
    type: types.GET_ALL_POLICY,
    payload: data,
  };
};
export const setAllPolicy = (data) => {
  return {
    type: types.SET_ALL_POLICY,
    payload: data,
  };
};
export const getPolicyError = (data) => {
  return {
    type: types.GET_POLICY_ERROR,
    payload: data,
  };
};
export const exportPolicyData = (data) => {
  return {
    type: types.GET_EXPORT_POLICY_DATA,
    payload: data,
  };
};
export const setExportPolicyData = (data) => {
  return {
    type: types.SET_EXPORT_POLICY_DATA,
    payload: data,
  };
};
export const setExportPolicyDataError = (data) => {
  return {
    type: types.SET_EXPORT_POLICY_DATA_ERROR,
    payload: data,
  };
};
export const clearPolicyPdfData = (data) => {
  return {
    type: types.CLEAR_POLICYPDF_DATA,
    payload: data,
  };
};
export const addPolicyMember = (data) => {
  return {
    type: types.ADD_POLICY_MEMBER,
    payload: data,
  };
};
export const addPolicyMemberSuccess = (data) => {
  return {
    type: types.ADD_POLICY_MEMBER_SUCCESS,
    payload: data,
  };
};
export const addPolicyMemberError = (data) => {
  return {
    type: types.ADD_POLICY_MEMBER_ERROR,
    payload: data,
  };
};
export const getInsuranceProvider = () => {
  return {
    type: types.GET_INSURANCE_PROVODERS,
  };
};
export const SetInsuranceProvider = (data) => {
  return {
    type: types.SET_INSURANCE_PROVODERS,
    payload: data,
  };
};
export const getTpaList = () => {
  return {
    type: types.GET_TPA_LISTS,
  };
};
export const SetTpaList = (data) => {
  return {
    type: types.SET_TPA_LISTS,
    payload: data,
  };
};
export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};
export const addPolicy = (data) => {
  return {
    type: types.ADD_POLICY,
    payload: data,
  };
};
export const addPolicySuccess = (data) => {
  return {
    type: types.ADD_POLICY_SUCCESS,
    data,
  };
};
export const addPolicyError = (data) => {
  return {
    type: types.ADD_POLICY_ERROR,
    data,
  };
};
export const viewPolicy = (data) => {
  return {
    type: types.VIEW_POLICY,
    payload: data,
  };
};
export const setPolicySuccess = (data) => {
  return {
    type: types.SET_POLICY_BYID_SUCCESS,
    payload: data,
  };
};
export const setPolicyError = () => {
  return {
    type: types.SET_POLICY_BYID_ERROR,
  };
};
export const networkHospital = (data, callback) => {
  return {
    type: types.NETWORK_HOSPITAL,
    payload: data,
    callback,
  };
};
export const downloadPolicyDoc = (data) => {
  return {
    type: types.DOWNLOAD_POLICY_DOC,
    payload: data,
  };
};
export const sampleAddMember = (data) => {
  return {
    type: types.SAMPLE_ADD_MEMBER,
    payload: data,
  };
};
export const addMemberSampleSuccess = (data) => {
  return {
    type: types.SAMPLE_ADD_MEMBER_SUCCESS,
    payload: data,
  };
};
export const resetMemberSamplePdf = () => {
  return {
    type: types.RESET_MEMEBR_SAMPLE_PDF,
  };
};
export const getHospitalListSuccess = (data) => {
  return {
    type: types.GET_HOSPITAL_LIST_SUCCESS,
    payload: data,
  };
};
export const getHospitalListError = () => {
  return {
    type: types.GET_HOSPITAL_LIST_ERROR,
  };
};
export const downloadPolicyDocSuccess = (data) => {
  return {
    type: types.DOWNLOAD_POLICY_DOC_SUCCESS,
    payload: data,
  };
};
export const clearPolicyDownloadState = (data) => {
  return {
    type: types.CLEAR_POLICY_DOWNLOAD_STATE,
    payload: data,
  };
};
export const viewMemberList = (data) => {
  return {
    type: types.VIEW_MEMBER_LIST,
    payload: data,
  };
};
export const setMemberListsuccess = (data) => {
  return {
    type: types.SET_MEMBER_LIST_SUCCESS,
    payload: data,
  };
};
export const addMIS = (data) => {
  return {
    type: types.ADD_MIS,
    payload: data,
  };
};
export const addMISSuccess = (data) => {
  return {
    type: types.ADD_MIS_SUCCESS,
    payload: data,
  };
};
export const addMISError = (data) => {
  return {
    type: types.ADD_MIS_ERROR,
    payload: data,
  };
};
export const resetaddMIS = (data) => {
  return {
    type: types.RESET_ADDMIS,
    payload: data,
  };
};
export const resetMemberList = (data) => {
  return {
    type: types.RESET_MEMBER_LIST,
    payload: data,
  };
};

export const employeeECardDownload = (data) => {
  return {
    type: types.EMPLOYEE_E_CARD_DOWNLOAD,
    payload: data,
  };
};

export const hrECardDownload = (data) => {
  return {
    type: types.HR_E_CARD_DOWNLOAD,
    payload: data,
  };
};

export const claimPDFDownload = (data) => {
  return {
    type: types.CLAIM_PDF_DOWNLOAD,
    payload: data,
  };
};

export const setHrECardList = (data) => {
  return {
    type: types.SET_HR_E_CARD,
    payload: data,
  };
};

export const setPolicyMemberList = (data) => {
  return {
    type: types.SET_POLICY_MEMBER_LIST,
    payload: data,
  };
};

export const getPolicyMemberList = () => {
  return {
    type: types.GET_POLICY_MEMBER_LIST,
  };
};

export const addSos = (data) => {
  return {
    type: types.ADD_SOS,
    payload: data,
  };
};
export const sosSuccess = (data) => {
  return {
    type: types.SOS_SUCCESS,
    payload: data,
  };
};

export const getExportMis = (data) => {
  return {
    type: types.GET_EXPORT_MIS,
    payload: data,
  };
};

export const addCdNumber = (data) => {
  return {
    type: types.ADD_CD_NUMBER,
    payload: data,
  };
};
export const addCdNumberSuccess = (data) => {
  return {
    type: types.ADD_CD_NUMBER_SUCCESS,
    data,
  };
};
export const addCdNumberError = (data) => {
  return {
    type: types.ADD_CD_NUMBER_SUCCESS,
    data,
  };
};
