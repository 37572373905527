export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_ERROR = 'ADD_EMPLOYEE_ERROR';
export const GET_ALL_EMPLOYEE = 'GET_ALL_EMPLOYEE';
export const SET_ALL_EMPLOYEE = 'SET_ALL_EMPLOYEE';
export const SEARCH_EMPLOYEE = 'SEARCH_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_ERROR = 'DELETE_EMPLOYEE_ERROR';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_ERROR = 'UPDATE_EMPLOYEE_ERROR';
export const UPDATE_EMPLOYEE_RESET = 'UPDATE_EMPLOYEE_RESET';
export const ACTIVE_EMPLOYEE_STATUS = 'ACTIVE_EMPLOYEE_STATUS';
export const ACTIVE_EMPLOYEE_STATUS_SUCCESS = 'ACTIVE_EMPLOYEE_STATUS_SUCCESS';
export const ACTIVE_EMPLOYEE_STATUS_ERROR = 'ACTIVE_EMPLOYEE_STATUS_ERROR';
export const ADD_EMPLOYEE_RESET = 'ADD_EMPLOYEE_RESET';
export const SET_META_PARAMS = 'SET_META_PARAMS';
export const NEXT_PAGE_EMPLOYEE = 'NEXT_PAGE_EMPLOYEE';
export const GET_EMPLOYEE_PERMISSIONS = 'GET_EMPLOYEE_PERMISSIONS';
export const SET_EMPLOYEE_PERMISSIONS = 'SET_EMPLOYEE_PERMISSIONS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const GET_EMPLOYEE_POLICY_LIST = 'GET_EMPLOYEE_POLICY_LIST';
export const SET_EMPLOYEE_POLICY_DATA = 'SET_EMPLOYEE_POLICY_DATA';
export const GET_POLICY_DETAIL_DATA = 'GET_POLICY_DETAIL_DATA';
export const SET_POLICY_DETAIL_DATA = 'SET_POLICY_DETAIL_DATA';
export const GET_EMPLOYEE_PROFILE = 'GET_EMPLOYEE_PROFILE';
export const SET_EMPLOYEE_PROFILE = 'SET_EMPLOYEE_PROFILE';
export const UPDATE_EMPLOYEE_PROFILE = 'UPDATE_EMPLOYEE_PROFILE';
export const SET_UPDATE_EMPLOYEE_PROFILE_SUCCESS = 'SET_UPDATE_EMPLOYEE_PROFILE_SUCCESS';
export const REGISTER_EMPLOYEE_ASSESSMENT = 'REGISTER_EMPLOYEE_ASSESSMENT';
export const SET_REGISTER_EMPLOYEE_ASSESSMENT_SUCCESS = 'SET_REGISTER_EMPLOYEE_ASSESSMENT_SUCCESS';
export const SET_META_PARAMS_ERROR = 'SET_META_PARAMS_ERROR';
export const UPDATE_MOBILE_NUMBER = 'UPDATE_MOBILE_NUMBER';
export const SET_UPDATE_MOBILE_SUCCESS = 'SET_UPDATE_MOBILE_SUCCESS';
export const GET_EXPORT_ALL_LIVES = 'GET_EXPORT_ALL_LIVES';
