import * as types from './actionTypes';

const initialState = {
  loading: false,
  created: false,
  updated: false,
  allPolicy: [],
  metaParams: {},
  policyPdfData: [],
  tpaList: [],
  insuranceProvider: [],
  policyById: [],
  errors: {},
  hospitalLink: {},
  policyDocument: [],
  sampleAddMember: [],
  addMISSuccess: false,
  memberList: [],
  eCardList: [],
  policyMemberList: [],
  sosSuccess: false,
};

const PolicyReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.GET_ALL_POLICY:
      return {
        ...state,
        loading: true,
      };
    case types.SET_ALL_POLICY:
      return {
        ...state,
        loading: false,
        allPolicy: action.payload,
      };
    case types.SET_META_PARAMS:
      return {
        ...state,
        loading: false,
        metaParams: action.payload,
      };
    case types.SET_EXPORT_POLICY_DATA:
      return {
        ...state,
        loading: false,
        policyPdfData: action.payload,
      };
    case types.CLEAR_POLICYPDF_DATA:
      return {
        ...state,
        loading: false,
        policyPdfData: [],
      };
    case types.ADD_POLICY_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        created: true,
      };
    case types.SET_POLICY_MEMBER_LIST:
      return {
        ...state,
        policyMemberList: action.payload,
      };
    case types.ADD_POLICY_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        created: false,
        errors: action.payload?.formErrors,
      };
    case types.SET_INSURANCE_PROVODERS:
      return {
        ...state,
        loading: false,
        insuranceProvider: action.payload,
      };
    case types.SET_TPA_LISTS:
      return {
        ...state,
        loading: false,
        tpaList: action.payload,
      };
    case types.ADD_POLICY:
      return {
        ...state,
        loading: true,
      };
    case types.SET_POLICY_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        created: false,
        policyById: action.payload,
      };
    case types.ADD_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        created: data,
      };
    case types.ADD_POLICY_ERROR:
      return {
        ...state,
        loading: false,
        errors: data,
      };
    case types.DOWNLOAD_POLICY_DOC_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.GET_HOSPITAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hospitalLink: action.payload[0],
      };
    case types.DOWNLOAD_POLICY_DOC_SUCCESS:
      return {
        ...state,
        loading: false,
        policyDocument: action.payload,
      };
    case types.ADD_MIS_SUCCESS:
      return {
        ...state,
        loading: false,
        addMISSuccess: true,
      };
    case types.CLEAR_POLICY_DOWNLOAD_STATE:
      return {
        ...state,
        policyDocument: [],
      };
    case types.SAMPLE_ADD_MEMBER_SUCCESS:
      return {
        ...state,
        sampleAddMember: action.payload,
      };
    case types.RESET_MEMEBR_SAMPLE_PDF:
      return {
        ...state,
        sampleAddMember: [],
      };
    case types.RESET_ADDMIS:
      return {
        ...state,
        addMISSuccess: false,
      };
    case types.ADD_MIS_ERROR:
      return {
        ...state,
        errors: action.payload?.formErrors,
      };
    case types.SET_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        memberList: action.payload,
      };
    case types.RESET_MEMBER_LIST:
      return {
        ...state,
        memberList: [],
      };
    case types.SET_HR_E_CARD:
      return {
        ...state,
        eCardList: action.payload,
      };
    case types.SOS_SUCCESS:
      return {
        ...state,
        sosSuccess: action.payload,
      };
    case types.ADD_CD_NUMBER:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_CD_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        created: data,
      };
    case types.ADD_CD_NUMBER_ERROR:
      return {
        ...state,
        loading: false,
        errors: data,
      };
    default:
      return state;
  }
};

export default PolicyReducer;
