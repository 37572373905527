/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as types from '../../wellnessOrganization/actionTypes';
import { startLoader, stopLoader } from '../../../utility/commonFunction';
import {
  setWellnessOrganization,
  getWellnessOrganizationSuccess,
  getWellnessOrganizationFailure,
  setMetaParams,
  addWellnessOrganizationSuccess,
  addWellnessOrganizationError,
} from '../../wellnessOrganization/actions';
import { getWellnessOrganizationApi, createWellnessProgramApi, getSosListApi } from '../requests/wellnessRequest';

function* getWellnessOrganizationHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getWellnessOrganizationApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessOrganization(data.data));
      yield put(getWellnessOrganizationSuccess());
    } else {
      yield put(setMetaParams([]));
      yield put(getWellnessOrganizationFailure());
    }
  } catch (err) {
    yield put(getWellnessOrganizationFailure(err.message || 'Something went wrong'));
    // console.log('error');
  } finally {
    yield stopLoader('transparent');
  }
}
function* addWellnessOrganizationHandler(value) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(createWellnessProgramApi, value?.data);
    if (data?.status) {
      yield put(addWellnessOrganizationSuccess(data));
      toast.success('Wellness organization added successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(addWellnessOrganizationError(data.message));
    }
  } catch (err) {
    yield put(addWellnessOrganizationError(err));
    toast.error('An error occurred while adding the wellness organization.', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    yield stopLoader('transparent');
  }
}
function* getSosListHandler(action) {
  yield startLoader('transparent');
  try {
    const { data } = yield call(getSosListApi, action.payload);
    if (data?.status) {
      yield put(setMetaParams(data.meta_params));
      yield put(setWellnessOrganization(data.data));
    } else {
      yield toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(getWellnessOrganizationFailure(data.message));
    }
  } catch (err) {
    yield put(getWellnessOrganizationFailure(err));
  } finally {
    yield stopLoader('transparent');
  }
}

export default function* wellnessSaga() {
  yield takeLatest(types.GET_WELLNESS_ORGANIZATION, getWellnessOrganizationHandler);
  yield takeLatest(types.ADD_WELLNESS_ORGANIZATION, addWellnessOrganizationHandler);
  yield takeLatest(types.GET_SOSLIST, getSosListHandler);
}
