/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';
import { objectToFormData } from '../../../utility/utility';

export const getAllPolicyApi = (values) => {
  const id = values?.OrgId;
  const payload = values.search ? values.search : '';
  const page = values.page ? values.page : 1;
  return DataService.get(`/policy/${id}/show?page=${page}&search=${payload}`);
};

export const getExportPolicyDataApi = (values) => {
  return DataService.get(`/policy/generate/pdf/${values}`);
};
export const addPolicyMemberApi = (values) => {
  const formData = new FormData();
  formData.append('csvfile', values.fileData.file);
  return DataService.post(`/policy/${values?.id}/upload/excel`, formData);
};
export const getInsuranceProviderListApi = () => {
  return DataService.get(`/policy/insurance/provider/detail`);
};
export const viewPolicyApi = (id) => {
  return DataService.get(`/policy/${id}`);
};
export const getTpaListApi = () => {
  return DataService.get(`/policy/tpa/detail`);
};
export const addPolicyApi = (values) => {
  const data = objectToFormData(values?.payload?.fileData);
  return DataService.post(`/policy/${values?.payload?.id}/add`, data);
};

export const getHospitalListApi = (values) => {
  return DataService.get(`policy/${values}/hospital/list`);
};
export const getPolicyDocApi = (values) => {
  return DataService.get(`policy/${values}/document/download`);
};
export const getAddmemberSampleApi = (payload) => {
  return DataService.get(`policy/sample/url/${payload.csv}`);
};
export const getMemberListApi = (id) => {
  return DataService.get(`policy/${id}/member/get`);
};
export const addMISApi = (payload) => {
  const formData = new FormData();
  formData.append('csvfile', payload.fileData.file);
  formData.append('csvfile1', payload.fileData.file1);
  return DataService.post(`policy/${payload.id}/upload/mis/excel`, formData);
};

export const getEmployeeECardDownloadApi = (data) => {
  return DataService.get(`employee/${data.user_id}/${data.policy_id}/ecard/view`);
};

export const getHrECardDownloadApi = (data) => {
  if (data.id !== undefined) {
    return DataService.get(`${data?.id}/employee/ecard/view?page=${data.page}&search=${data.search}`);
  }
};

export const getClaimPDFDownloadApi = (data) => {
  return DataService.get(`employee/${data.policy_id}/claim_pdf/view`);
};

export const getPolicyMemberListApi = () => {
  return DataService.get(`policy/cover/member`);
};
export const addSosApi = (data) => {
  return DataService.get(`claim/employee/sos?policyId=${data.policyId}`);
};
export const getExportMisApi = (data) => {
  return DataService.get(`policy/${data}/mis/export`);
};
export const addCdNumberApi = (payload) => {
  const data = { cd_number: payload.cd_number };
  return DataService.post(`policy/${payload?.id}/updateCDNumber`, data);
};
