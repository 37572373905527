/* eslint-disable import/no-cycle */
import { DataService } from '../../../config/dataService/dataService';
import { DataService2 } from '../../../config/dataService/dataServiceSecondary';
import { getQueryString } from '../../../utility/utility';

export const addOrganizationApi = (values) => {
  const formData = new FormData();
  formData.append('companyName', values.name);
  formData.append('companyLogo', values.companyLogo);
  formData.append('panNumber', values.panNumber);
  formData.append('gstNumber', values.gstNumber);
  return DataService.post('/organizations', formData);
};

export const updateOrganizationApi = (values) => {
  const formData = new FormData();
  formData.append('companyName', values.companyName);
  formData.append('panNumber', values.panNumber);
  formData.append('gstNumber', values.gstNumber);
  if (values) {
    formData.append('companyLogo', values.companyLogo);
  }
  formData.append('_method', 'PUT');
  return DataService.post(`/organizations/${values.id}`, formData);
};
export const getAllOrganizationApi = () => {
  return DataService.get('/organizations');
};
export const searchOrganizationApi = (values) => {
  return DataService.get(`/organizations?${getQueryString(values)}`);
};
export const getOrganizationByIdApi = (values) => {
  const Id = values?.data?.id;
  return DataService.get(`/organizations/${Id}`);
};
export const getNextPageOrganizationApi = (values) => {
  return DataService.get(`/organizations?${getQueryString(values)}`);
};

export const getOrganizationListApi = (values) => {
  return DataService.get(`/organization/list?search=${values.data}`);
};

export const getExportOrganizationApi = (value) => {
  return DataService.get(`/organizations/export/csv?status=${value.status}`);
};

export const addCalendarApi = (values) => {
  const payload = {
    orgnization_id: values.orgnization_id,
    created_hr_id: values.created_hr_id,
    activity_name: values.activityName,
    date_of_activity: values.date,
    amount: values.budget,
    activity_type: values.mode,
    location: values.location,
    description: values.description,
  };

  return DataService2.post('/wellness-activity', payload);
};

export const getCalendarApi = (value) => {
  return DataService2.get(`/wellness-activity?page=${value.page}&orgid=${value.orgId}`);
};

export const getwellnessActivityHistoryApi = (value) => {
  return DataService2.get(`/wellness-activity-history/history/${value.id}`);
};
