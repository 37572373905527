import * as types from './actionTypes';

export const getWellnessOrganization = (data) => {
  return {
    type: types.GET_WELLNESS_ORGANIZATION,
    payload: data,
  };
};
export const setWellnessOrganization = (data) => {
  return {
    type: types.SET_WELLNESS_ORGANIZATION,
    payload: data,
  };
};
export const getWellnessOrganizationSuccess = () => {
  return {
    type: types.GET_WELLNESS_ORGANIZATION_SUCCESS,
  };
};
export const getWellnessOrganizationFailure = () => {
  return {
    type: types.GET_WELLNESS_ORGANIZATION_FAILURE,
  };
};
export const setMetaParams = (data) => {
  return {
    type: types.SET_META_PARAMS,
    payload: data,
  };
};
export const addWellnessOrganization = (data) => {
  return {
    type: types.ADD_WELLNESS_ORGANIZATION,
    data,
  };
};
export const addWellnessOrganizationError = (data) => {
  return {
    type: types.ADD_WELLNESS_ORGANIZATION_ERROR,
    data,
  };
};
export const addWellnessOrganizationSuccess = (data) => {
  return {
    type: types.ADD_WELLNESS_ORGANIZATION_SUCCESS,
    data,
  };
};
export const getSosList = (data) => {
  return {
    type: types.GET_SOSLIST,
    payload: data,
  };
};
